export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const app = useNuxtApp()

    if (from.fullPath.includes('result')) {
      await app.$dialog({
        title: '구매가 완료된 상품입니다',
        message: '접근할 수 없는 페이지입니다',
      })
      return app.$router.push('/app/myzipup')
    }
  }
})
